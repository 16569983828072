import React, { useState, useEffect } from "react"
import { getImage }  from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

const Slide = ({data, active}) => {
  const [width, setWidth] = useState(null)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  let isMobile = (width <= 768)

  const video = data.sliderMeta.video
  
  const image = getImage(data.featuredImage.node.localFile)
  const bgImage = convertToBgImage(image)

  return (
    <div className={"carousel-item" + (active ? " active" : "")}>
      <BackgroundImage 
        {...bgImage}
        className="carousel-item__container"
      >
        {video && !isMobile && 
          <div className="carousel-item__video">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4"></source>
            </video>
          </div>
        }
        <div className="container slide-container" style={{bottom: 0}}>
          <div>
            <div className="carousel-overlay p-4">
              {active ? <h1>{data.title}</h1> : <h2>{data.title}</h2>}
              <div className="carousel-overylay__content" dangerouslySetInnerHTML={{ __html: data.content }}></div>
              <Link className="btn btn-light text text-primary" to={data.sliderMeta.link.url}>
                Read more...
              </Link>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Slide