import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo';

import Slider from "../components/slider/slider"
import Testimonials from "../components/testimonials"

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: {eq: "home"}) {
        ...pageData
      }
    }
  `)

  const { content } = data.wpPage
  
  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      <Slider />
      <main dangerouslySetInnerHTML={{ __html: content }}></main>
      <Testimonials />
    </React.Fragment>
  )
}

export default IndexPage