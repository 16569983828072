import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage }  from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Testimonials = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Blackgang-Chine-Flume.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)


  return (
    <BackgroundImage 
        {...bgImage}
        style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", backgroundPosition: "center", backgroundSize: "cover"}}
        className="section-testimonials"
        preserveStackingContext
    >
      <div id="testimonialsCarousel" className="container carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#testimonialsCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#testimonialsCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#testimonialsCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#testimonialsCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#testimonialsCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="5000">
            <div className="d-flex justify-content-center flex-column h-100 text-center">
              <blockquote>
                <p>Had an amazing time with my kids. They just enjoyed every bit of the park, the dinosaurs were gigantic and looked so real. The rides were really fun and the views were breath-taking</p>
              </blockquote>
              <cite>
                <p>Seny</p>
              </cite>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex justify-content-center flex-column h-100 text-center">
              <blockquote>
                <p>Fantastic place! Perfect for creating the best memories. Ideal for anyone 0-100+! The location is beautiful - one of my favourite places on the Isle of Wight.</p>
              </blockquote>
              <cite>
                <p>Amanda</p>
              </cite>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex justify-content-center flex-column h-100 text-center">
              <blockquote>
                <p>Full of memories and the dinosaurs are the best I've seen. Fantastic place.</p>
              </blockquote>
              <cite>
                <p>Gavin</p>
              </cite>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex justify-content-center flex-column h-100 text-center">
              <blockquote>
                <p>I have 4 children, the smallest being 21 months and eldest being 8, and they all loved their time here. There’s so much to do to keep them all entertained and can reuse your tickets for up to 7 days!</p>
              </blockquote>
              <cite>
                <p>Sarah</p>
              </cite>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex justify-content-center flex-column h-100 text-center">
              <blockquote>
                <p>Absolutely fantastic day we had here, during our honeymoon. We have no little ones but thoroughly enjoyed ourselves. There's lots of new bits since I went when I was little and lots to see and do. We had a blast and got some brilliant funny pictures. Would highly recommend.</p>
              </blockquote>
              <cite>
                <p>Vanessa</p>
              </cite>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Testimonials