import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Slide from "./slide"

const Slider = () => {
  const data = useStaticQuery(graphql`
    {
      allWpSlider(
        sort: {
          fields: dateGmt
          order: DESC
        }
      ) {
        edges {
          node {
            id
            title
            content
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      breakpoints: [576, 768]
                    )
                  }
                }
              }
            }
            sliderMeta {
              video
              link {
                url
                title
              }
            }
          }
        }
      }
    }
  `)

  const slides = data.allWpSlider.edges.map(({node}, index) => {
    return(
      <Slide data={node} key={node.id} active={(index===0)} />
    )
  })

  return (
    <div id="homeSlider" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        {slides}
      </div>

      <button className="carousel-control-prev" type="button" data-bs-target="#homeSlider" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#homeSlider" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Slider